import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core/";
import { Image } from "cloudinary-react";
import Header from "../components/navigation/header";
import { useStyles } from "../utils/uiComponents";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ConnectPage() {
  const [aboutMeImg, setAboutMeImg] = useState();
  const classes = useStyles();

  const loadImages = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_HEROKU_BACKEND + "api/aboutMe"
      );
      const data = await res.json();
      setAboutMeImg(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadImages();
  }, []);

  return (
    <Grid container className="background">
      <Grid item xs={1}>
        <Header />
      </Grid>
      {aboutMeImg && aboutMeImg !== undefined ? (
        <Grid item xs={12} md={11} className={classes.connectImgGrid}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography className={classes.introTop} variant="h1">
                Hey, thanks for visiting
              </Typography>
              <h3 style={{ fontSize: 16, fontWeight: 700 }}>
                I appreciate YOU. Without you, I'd just be taking pictures,
                making videos, and designing graphics for my own weird self.
                But, with you by my side, I know there are other weirdos out
                there who connect to my work. So, really, thank you for the
                support!
              </h3>
              <h3 className={classes.h3bold}>
                Just so you know a bit more about me: I'm Jordan, originally
                from Denver, Colorado USA (shoutout to the 303!) currently
                residing in Tel Aviv Israel. I've been taking video and pictures
                for my soul since I was 15 and for people for close to a decade.
                I began working with clients when I was a freshman in college
                and wanted to pick up the some extra side cash. Next thing I
                know, I'm majoring in film production as part of a triple major
                in linguistics and Arabic along with managing a small side
                business in video editing and photography. I've had the honor of
                working on documentary productions for Spark Media out of
                Washington, D.C., managing production and design for the Israel
                Defense Force's Arabic Spokesman, amongst other freelance
                production, design, and videography roles.
              </h3>
              <h3 className={classes.h3bold}>
                This site showcases mainly artistic and documentary work I have
                done. When I'm not working for myself, I also am a co-managing
                partner at JB Upscale (www.jbupscale.com) where I manage the
                marketing and creative department, providing strategy
                development, asset design, video production, copywriting, and
                web design services for startups.
              </h3>
              <h3 className={classes.h3bold}>
                So, that's it about me. Enjoy the visuals, peruse the site, and
                be in touch if you want a commission or to collaborate.
              </h3>
            </Grid>
            <Grid xs={12} md={6} style={{ textAlign: "center" }}>
              <div class="browser-mockup with-url">
                {aboutMeImg &&
                  aboutMeImg.map((imageId, index) => (
                    <Image
                      style={{
                        objectFit: "cover",
                        height: "80vh",
                      }}
                      cloudName="dfqxmzk4g"
                      publicId={imageId}
                      gravity="face"
                      crop="thumb"
                    />
                  ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item style={{ textAlign: "center" }}>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
}
