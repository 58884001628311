import React from "react";
import { Grid } from "@material-ui/core/";
import { Image } from "cloudinary-react";
import { useStyles } from "../utils/uiComponents";

export default function Gallery(props) {
  const { imageIds, oneImage, openImage, dontShowImage, showOneImage } = props;
  const classes = useStyles();

  return (
    <Grid container>
      {openImage ? (
        <Grid item xs={12} onClick={dontShowImage}>
          <Image
            className={classes.imageStylying}
            cloudName="dfqxmzk4g"
            publicId={oneImage}
            gravity="face"
            crop="thumb"
          />
        </Grid>
      ) : null}

      {imageIds.map((imageId, index) => (
        <Grid item xs={6} key={index}>
          {}
          <Image
            style={{
              width: "99%",
              objectFit: "cover",
              height: 270,
              opacity: openImage ? 0.3 : 1,
            }}
            cloudName="dfqxmzk4g"
            publicId={imageId}
            onClick={(e) =>
              openImage ? dontShowImage() : showOneImage(imageId)
            }
            gravity="face"
            crop="thumb"
          />
        </Grid>
      ))}
    </Grid>
  );
}
