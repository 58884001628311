import React from "react";
import { Grid } from "@material-ui/core/";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";

export default function socialMedia() {
  return (
    <Grid container style={{ display: "grid" }}>
      <Grid item style={{ paddingTop: 7, display: "table", margin: "auto" }}>
        <div style={{ display: "table-cell" }}>
          <a
            href="https://www.linkedin.com/company/jordan-productions-tlv/"
            target="_blank"
            alt="linkedin"
            style={{ textDecoration: "none", color: "#6843d1" }}
            rel="noreferrer"
          >
            <LinkedInIcon />
          </a>
        </div>
        <div style={{ display: "table-cell", padding: 7 }}>
          <a
            href="https://www.instagram.com/jordi_digital/?hl=en"
            target="_blank"
            alt="linkedin"
            style={{ textDecoration: "none", color: "#6843d1" }}
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
        </div>
        <div style={{ display: "table-cell" }}>
          <a
            href="https://www.facebook.com/Jordidigital"
            target="_blank"
            alt="linkedin"
            style={{ textDecoration: "none", color: "#6843d1" }}
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
        </div>
      </Grid>
    </Grid>
  );
}
