import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Logo from "../../utils/images/JordanWebsiteLogo.png";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SocialMedia from "../socialMedia"
import "./style.scss";

const menulists = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "About Me",
    link: "/aboutme",
  },
  {
    id: 3,
    name: "Connect",
    link: "/connect",
  },
  {
    id: 4,
    name: "Photos",
    submenu: [
      {
        id: 1,
        name: "Urban",
        link: "/urban",
      },
      {
        id: 2,
        name: "Nature",
        link: "/nature",
      },
      {
        id: 3,
        name: "Portraits",
        link: "/portraits",
      },
      {
        id: 4,
        name: "Food",
        link: "/food",
      },
      {
        id: 5,
        name: "Sports",
        link: "/sports",
      },
    ],
  },
  {
    id: 5,
    name: "Videos",
    link: "/videos",
  },
  {
    id: 6,
    name: "Graphic Design",
    link: "/graphic-design",
  },
];

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  text: {
    textTransform: "uppercase",
    fontize: "1.5rem",
    color: "#f1783f",
    lineHeight: "2.57",
    fontWeight: "300",
  },
}));

const MobileMenu = ({ showMobile, setShowMobile }) => {
  const classes = useStyles();
  const [menuId, setActiveMid] = useState();

  function handleExpandClick(id) {
    setActiveMid(id);
    if (menuId === id) {
      setActiveMid();
    }
  }
  return (
    <Fragment>
      <Grid className={`mobileMenu ${showMobile ? "" : "hidden"}`}>
      <img
          src={Logo}
          alt="logo"
        ></img>
        <List>
          {menulists.map((item) => {
            return (
              <ListItem key={item.id}>
                {!item.submenu ? (
                  <Link to={item.link}>{item.name}</Link>
                ) : (
                  <Grid
                    className="mItem"
                    onClick={() => handleExpandClick(item.id)}
                  >
                    <span>{item.name}</span>
                    <Grid
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: menuId === item.id,
                      })}
                    >
                      <KeyboardArrowDownIcon style={{color: '#6843d1'}}/>
                    </Grid>
                  </Grid>
                )}
                {item.submenu
                  ? item.submenu.map((subitem) => {
                      return (
                        <Collapse
                          key={subitem.id}
                          className="subMenu"
                          in={menuId === item.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Link to={subitem.link}>{subitem.name}</Link>
                        </Collapse>
                      );
                    })
                  : ""}
              </ListItem>
            );
          })}
        </List>
        <SocialMedia/>
        <Grid
          onClick={() => setShowMobile(!showMobile)}
          className={`menuHoverlay ${showMobile ? "show" : ""}`}
        ></Grid>
      </Grid>
    </Fragment>
  );
};

export default MobileMenu;
