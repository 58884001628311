import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core/";
import Gallery from "../components/gallery";
import Header from "../components/navigation/header";
import { useStyles } from "../utils/uiComponents";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ConnectPage() {
  const classes = useStyles();
  const [imageIds, setImageIds] = useState();
  const [oneImage, setOneImage] = useState("");
  const [openImage, setOpenImage] = useState(false);

  const loadImages = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_HEROKU_BACKEND + "api/nature"
      );
      const data = await res.json();
      setImageIds(data);
    } catch (err) {
      console.error(err);
    }
  };
  

  const dontShowImage = () => {
    setOpenImage(false);
  };
  const showOneImage = (e) => {
    setOpenImage(true);
    setOneImage(e);
  };

  useEffect(() => {
    loadImages();
  }, []);

  let galleryProps = {
    imageIds,
    setImageIds,
    oneImage,
    setOneImage,
    openImage,
    setOpenImage,
    dontShowImage,
    showOneImage,
  };

  return (
    <Grid container className="background" style={{ overflow: "hidden" }}>
      <Grid item xs={1}>
        <Header />
      </Grid>
      {imageIds && imageIds !== undefined ? (
        <Grid item xs={12} md={11} className={classes.photoGrid}>
          <Typography
            style={{
              textAlign: "center",
              paddingBottom: 10,
            }}
            className={classes.introTop}
            variant="h1"
          >
            NATURE
          </Typography>
          <Gallery {...galleryProps} />
        </Grid>
      ) : (
        <Grid style={{ margin: "auto", textAlign: "center" }}>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
}
