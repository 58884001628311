import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/";
import "./App.css";
import "./App.scss";
import landingPage from "./pages/landingPage";
import connectPage from "./pages/connectPage";
import aboutMePage from "./pages/aboutMePage";
import UrbanPage from "./pages/urbanPage";
import FoodPage from "./pages/foodPage";
import SportsPage from "./pages/sportsPage";
import NaturePage from "./pages/naturePage";
import PortraitsPage from "./pages/portraitsPage";
import videosPage from "./pages/videosPage";
import graphicPage from "./pages/graphicDesign";
import landingPage2 from "./pages/landingPage2";

const theme = createMuiTheme({
  typography: {
    // fontFamily: 'Work Sans', sans-serif;
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Poppins, sans-serif",
      },
    },
    MuiDrawer: {
      paperAnchorLeft: {
        color: "white",
        background: "#0B0500",
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          {/* <Route exact path="/test" component={landingPage} /> */}
          <Route exact path="/" component={landingPage2} />
          <Route exact path="/connect" component={connectPage} />
          <Route exact path="/nature" component={NaturePage} />
          <Route exact path="/urban" component={UrbanPage} />
          <Route exact path="/food" component={FoodPage} />
          <Route exact path="/sports" component={SportsPage} />
          <Route exact path="/portraits" component={PortraitsPage} />
          <Route exact path="/aboutme" component={aboutMePage} />
          <Route exact path="/videos" component={videosPage} />
          <Route exact path="/graphic-design" component={graphicPage} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
