import React, { useEffect, useState } from "react";
import Logo from "../../utils/images/JordanWebsiteLogo.png";
import {
  Typography,
  Collapse,
  ListItemText,
  List,
  ListItem,
} from "@material-ui/core";
import { useStyles } from "../../utils/uiComponents";
import HomeIcon from "@material-ui/icons/Home";
import FaceIcon from "@material-ui/icons/Face";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import PhotoIcon from "@material-ui/icons/Photo";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AlbumIcon from "@material-ui/icons/Album";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import SocialMedia from "../socialMedia";

export default function Drawer2() {
  const toggleMenu = () => {
    const navigation = document.querySelector(".navigation");
    if (document) {
      document.querySelector(".toggle").onclick = function () {
        this.classList.toggle("active");
        navigation.classList.toggle("active");
      };
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [openAccountMenu, setOpenAccountMenu] = useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    toggleMenu();
  }, []);

  return (
    <div className="container">
      <div className="navigation">
        <img
          style={{ width: "100%", paddingTop: 10 }}
          src={Logo}
          alt="logo"
        ></img>
        <a href="/" className={classes.drawerLink}>
          <ListItem button className={classes.marginBottom}>
            <ListItemIcon className={classes.listItemIcon}>
              <HomeIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText
              className={classes.iconText}
              primary={<Typography variant="H6">HOME</Typography>}
            />
          </ListItem>
        </a>
        <a href="/aboutme" className={classes.drawerLink}>
          <ListItem button className={classes.marginBottom}>
            <ListItemIcon className={classes.listItemIcon}>
              <FaceIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText
              className={classes.iconText}
              primary={<Typography variant="H6">ABOUT ME</Typography>}
            />
          </ListItem>
        </a>
        <a href="/connect" className={classes.drawerLink}>
          <ListItem button className={classes.marginBottom}>
            <ListItemIcon className={classes.listItemIcon}>
              <PhoneForwardedIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText
              className={classes.iconText}
              primary={<Typography variant="H6">CONNECT</Typography>}
            />
          </ListItem>
        </a>
        <ListItem button className={classes.marginBottom} onClick={handleClick}>
          <ListItemIcon className={classes.listItemIcon}>
            <PhotoIcon className={classes.iconColor} />
          </ListItemIcon>
          <ListItemText
            className={classes.iconText}
            style={{ color: "#6843d1" }}
            primary={<Typography variant="H6">PHOTOS</Typography>}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <a
              href="/urban"
              style={{ textDecoration: "none", color: "#6843d1" }}
            >
              <ListItem
                button
                className={classes.marginBottom}
                className={classes.nested}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText
                  className={classes.iconText}
                  primary={<Typography variant="H6">URBAN</Typography>}
                />
              </ListItem>
            </a>
            <a
              href="/nature"
              style={{ textDecoration: "none", color: "#6843d1" }}
            >
              <ListItem
                button
                className={classes.marginBottom}
                className={classes.nested}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText
                  className={classes.iconText}
                  primary={<Typography variant="H6">NATURE</Typography>}
                />
              </ListItem>
            </a>
            <a
              href="/portraits"
              style={{ textDecoration: "none", color: "#6843d1" }}
            >
              <ListItem
                button
                className={classes.marginBottom}
                className={classes.nested}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText
                  className={classes.iconText}
                  primary={<Typography variant="H6">PORTRAITS</Typography>}
                />
              </ListItem>
            </a>
            <a
              href="/food"
              style={{ textDecoration: "none", color: "#6843d1" }}
            >
              <ListItem
                button
                className={classes.marginBottom}
                className={classes.nested}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText
                  className={classes.iconText}
                  primary={<Typography variant="H6">FOOD</Typography>}
                />
              </ListItem>
            </a>
            <a
              href="/sports"
              style={{ textDecoration: "none", color: "#6843d1" }}
            >
              <ListItem
                button
                className={classes.marginBottom}
                className={classes.nested}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText
                  className={classes.iconText}
                  primary={<Typography variant="H6">SPORTS</Typography>}
                />
              </ListItem>
            </a>
          </List>
        </Collapse>
        <a href="/videos" className={classes.drawerLink}>
          <ListItem button className={classes.marginBottom}>
            <ListItemIcon className={classes.listItemIcon}>
              <VideoLibraryIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText
              className={classes.iconText}
              primary={<Typography variant="H6">VIDEOS</Typography>}
            />
          </ListItem>
        </a>
        <a href="/graphic-design" className={classes.drawerLink}>
          <ListItem button className={classes.marginBottom}>
            <ListItemIcon className={classes.listItemIcon}>
              <AlbumIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText
              className={classes.iconText}
              primary={<Typography variant="H6">GRAPHIC DESIGN</Typography>}
            />
          </ListItem>
        </a>
        <div style={{ paddingLeft: 80 }}>
          <SocialMedia />
        </div>
      </div>
      <div className="toggle">
        <ChevronRightIcon
          fontSize="large"
          style={{ margin: "3px 0px 0px 3px", color: "#5b1ee1" }}
        />
      </div>
    </div>
  );
}
