import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core/";
import { Image } from "cloudinary-react";
import Header from "../components/navigation/header";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "../utils/uiComponents";

export default function ConnectPage() {
  const classes = useStyles();
  const [connectImg, setConnectImg] = useState();
  const loadImages = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_HEROKU_BACKEND + "api/aboutMe"
      );
      const data = await res.json();
      setConnectImg(data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    loadImages();
  }, []);

  return (
    <Grid container className="background">
      <Grid item xs={1}>
        <Header />
      </Grid>
      {connectImg && connectImg !== undefined ? (
        <Grid item xs={12} md={11} className={classes.connectImgGrid}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" className={classes.introTop}>
                Let's connect
              </Typography>
              <h3 style={{ fontSize: 18 }}>
                Have a cool idea but don't know how to do it yourself? Be in
                touch!
              </h3>
              <h3 style={{ fontSize: 18, fontWeight: 700 }}>
                Want to commission a specific design, set of pictures, or video?
                Be in touch!
              </h3>
              <h3 style={{ fontSize: 18, fontWeight: 700 }}>
                Want to collaborate on a project? Be in touch!
              </h3>
              <h3 style={{ fontSize: 18, fontWeight: 700 }}>
                Just want to talk about life, cool visuals, and other fun stuff?
                Be in touch!
              </h3>
              <a
                style={{
                  paddingTop: 5,
                  textDecoration: "underline",
                  color: "white",
                }}
                href="mailto:Email:kastrinsky.jordan@gmail.com"
              >
                <h2>Email: kastrinsky.jordan@gmail.com</h2>
              </a>
              <h2 style={{ paddingTop: 5, textDecoration: "underline" }}>
                Phone: +972 54 2531533
              </h2>
            </Grid>
            <Grid xs={12} md={6} style={{ textAlign: "center" }}>
              <div class="browser-mockup with-url">
                {connectImg &&
                  connectImg.map((imageId, index) => (
                    <Image
                      style={{
                        objectFit: "cover",
                        height: "80vh",
                      }}
                      cloudName="dfqxmzk4g"
                      publicId={imageId}
                      gravity="face"
                      crop="thumb"
                    />
                  ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid style={{ textAlign: "center" }}>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
}
