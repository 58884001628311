import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import "./ImageSlider.css";

const NextArrow = ({ onClick }) => {
  return (
    <div className="nextArrow" onClick={onClick}>
      <BsChevronRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow" onClick={onClick}>
      <BsChevronLeft />
    </div>
  );
};

const ImageSlider = ({ images, slidesToShow = 3 }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [youtubeVideos, setYoutebeVideos] = useState("");
  const YOUTUBE_PLAYLIST_ITEM_API = `https://www.googleapis.com/youtube/v3/playlistItems`;

  const fetchVideos = () => {
    axios
      .get(
        `${YOUTUBE_PLAYLIST_ITEM_API}?part=snippet&playlistId=PLtoTAnrde4Y_B873kJf6XcUQvhemi--OY&maxResults=50&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
      )
      .then((res) => {
        setYoutebeVideos(res.data.items);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const settings = {
    centerMode: true,
    infinite: true,
    dots: false,
    speed: 300,
    slidesToShow: slidesToShow,
    centerPadding: "0",
    swipeToSlide: true,
    focusOnSelect: true,
    nextArrow: <NextArrow onClick />,
    prevArrow: <PrevArrow onClick />,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 1490,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const templateImages = youtubeVideos ? (
    youtubeVideos.map((image, idx) => {
    // console.log(image.snippet.resourceId.videoId)
    if (image !== null) {
      return (
        <div
          className={idx === imageIndex ? "activeSlide" : "slide"}
          key={image.id}
        >
          <div className="slideWrapper">
            {/* {image.code} */}
            <iframe
              title={idx}
              width="560"
              height="315"
              src={
                "https://www.youtube.com/embed/" +
                image.snippet.resourceId.videoId
              }
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
      );
    }
    return null;
  })) : null

  return <Slider {...settings}>{templateImages}</Slider>;
};

export default ImageSlider;
