import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  iconColor: {
    color: "#6843d1",
  },
  iconText: {
    color: "#6843d1",
    paddingLeft: 20,
    fontWeight: 900,
    fontSize: 17,
  },
  navigationContainer: {
    display: "flex",
    height: "100vh",
    background: "#0b0500",
    paddingLeft: 20,
    color: "#ffff",
  },
  navigationLogoAndSomeText: {
    margin: "40px auto auto auto",
    textAlign: "center",
  },
  homeContainer: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
  },
  homeLeftCheeck: {
    width: "100%",
    height: "100%",
    background: "#0B0500 ",
  },
  homeRightCheeck: {
    width: "100%",
    background: "#C4C4C4",
  },
  homeLogoAndSomeText: {
    paddingTop: 90,
    margin: "auto",
    textAlign: "center",
  },
  link: {
    color: "#ffff",
    textDecoration: "none",
    textAlign: "center",
    margin: "auto",
  },
  drawerLink: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#323232",
    },
  },
  active: {
    background: "red",
  },
  divider: {
    paddingTop: 14,
    borderBottom: "1px solid #CDCDCB",
    width: "20%",
    margin: "auto",
    textAlign: "center",
  },
  logo: {
    width: "100%",
  },
  landingLogo: {
    width: "50%",
  },
  listItemIcon: {
    textAlign: "center",
    margin: "auto",
    display: "block",
  },
  marginBottom: {
    height: 40,
  },
  imageStylying: {
    position: "fixed",
    objectFit: "cover",
    width: "auto",
    margin: "auto",
    maxWidth: "90%",
    maxHeight: "90%",
    height: "auto",
    zIndex: 100,
    top: "50%",
    left: "50%",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
  },
  paddingBottom: {
    paddingBottom: 5,
  },
  paddingBottom30: {
    paddingBottom: 30,
  },
  h3bold: {
    fontSize: 16,
    marginTop: 20,
    fontWeight: 700,
  },
  h1bold: {
    fontSize: 24,
    fontWeight: 900,
    textAlign: "center",
  },
  connectImgGrid: {
    color: "#ffff",
    padding: "40px 0px 0px 40px",
    [theme.breakpoints.down("md")]: {
      padding: "40px 10px 0px 10px",
    },
  },
  photoGrid: {
    color: "#ffff",
    paddingLeft: 40,
    paddingTop: 20,
    [theme.breakpoints.down("md")]: {
      padding: "20px 5px 0px 5px",
    },
  },
  introTop: {
    fontSize: 48,
    fontWeight: 900,
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
  topVideo: {
    fontSize: 48,
    fontWeight: 900,
    textAlign: "center",
    paddingBottom: 50,
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
      fontSize: 38,
    },
  },
}));
