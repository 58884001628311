import React, { useState } from "react";
import Drawer from "./drawer";
import MobileDrawer from "./mobileDrawer";
import {
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core/";
import Logo from "../../utils/images/JordanWebsiteLogo.png";
import MenuIcon from "@material-ui/icons/Menu";

function Header() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [showMobile, setShowMobile] = useState(false);

  return (
    <div>
      {md ? (
        <Drawer />
      ) : (
        <AppBar
          position="fixed"
          className="backgroundDrawer"
        >
          <Toolbar>
            <IconButton color="inherit" aria-label="menu">
              <MenuIcon onClick={() => setShowMobile(!showMobile)} />
            </IconButton>
          </Toolbar>
          <MobileDrawer showMobile={showMobile} setShowMobile={setShowMobile} />
        </AppBar>
      )}
    </div>
  );
}

export default Header;
