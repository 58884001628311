import React from "react";
import { Grid, Typography } from "@material-ui/core/";
import Header from "../components/navigation/header";
import VideosCarousel from "../components/videos/videosCarousel";
import { useStyles } from "../utils/uiComponents";
import "../App.css";

export default function ConnectPage() {
  const classes = useStyles();
  return (
    <Grid container className="background">
      <Grid item xs={1}>
        <Header />
      </Grid>
      <Grid
        item
        xs={11}
        style={{
          color: "#ffff",
          paddingTop: 40,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.topVideo} variant="h1">
              Check out some of my projects
            </Typography>
          </Grid>
          <Grid xs={12} className="smallContaier">
            <Typography
              className={classes.h1bold}
              variant="h1"
              style={{ padding: "20px 0px" }}
            >
              Advertisments
            </Typography>
            <VideosCarousel />
          </Grid>
          <Grid xs={12} className="smallContaier">
            <Typography
              className={classes.h1bold}
              variant="h1"
              style={{ padding: "20px 0px" }}
            >
              Advertisments
            </Typography>
            <VideosCarousel />
          </Grid>
          <Grid xs={12} className="smallContaier">
            <Typography
              className={classes.h1bold}
              variant="h1"
              style={{ padding: "20px 0px" }}
            >
              Advertisments
            </Typography>
            <VideosCarousel />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
