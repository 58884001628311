import React, { useEffect, useState } from "react";
import { Grid, Typography, Link } from "@material-ui/core/";
import { useStyles } from "../utils/uiComponents";
import { Image, Video } from "cloudinary-react";
import Carousel from "react-material-ui-carousel";
import SocialMedia from "../components/landingSocialMedia";
import Logo from "../utils/images/JordanWebsiteLogo.png";
import AOS from "aos";
import "aos/dist/aos.css";

export default function LandingPage() {
  const classes = useStyles();
  const [landingImg, setlandingImg] = useState();
  const loadImages = async () => {
    try {
      const res = await fetch(
        process.env.REACT_APP_HEROKU_BACKEND + "api/landingPage"
      );
      const data = await res.json();
      setlandingImg(data);
    } catch (err) {
      console.error(err);
    }
  };

  AOS.init({
    duration: 1000,
  });

  useEffect(() => {
    loadImages();
  }, []);

  return (
    <Grid container className={classes.homeContainer}>
      <Carousel
        indicatorIconButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        {landingImg &&
          landingImg.map((imageId, index) => (
            <Image
              style={{
                width: "100%",
                objectFit: "cover",
                height: "100vh",
                filter: "grayscale(100%)",
                opacity: "0.6",
              }}
              cloudName="dfqxmzk4g"
              publicId={imageId}
              gravity="face"
              crop="thumb"
            />
          ))}
      </Carousel>
      <Grid
        style={{
          zIndex: 100,
          position: "fixed",
          top: "45%",
          left: "50%",
          /* bring your own prefixes */
          transform: "translate(-50%, -50%)",
        }}
        className={classes.homeLogoAndSomeText}
        data-aos="zoom-out-up"
      >
        <Grid item xs={12}>
          <img className={classes.landingLogo} src={Logo} alt="logo"></img>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            style={{ fontSize: 30, paddingTop: 10, fontWeight: 300 }}
          >
            Visual Artist/Digital Designer/Storyteller
          </Typography>
        </Grid>
        <Grid container style={{ paddingTop: 20 }}>
          <Grid item xs={12} style={{ paddingBottom: 5 }}>
            <Link className={classes.link} href={"/"}>
              <Typography style={{ fontSize: 19, paddingTop: 3 }} variant="h3">
                Home
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 5 }}>
            <Link className={classes.link} href={"/aboutme"}>
              <Typography style={{ fontSize: 19, paddingTop: 3 }} variant="h3">
                About me
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 5 }}>
            <Link className={classes.link} href={"/connect"}>
              <Typography style={{ fontSize: 19, paddingTop: 3 }} variant="h3">
                Connect
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 5 }}>
            <Link className={classes.link} href={"/urban"}>
              <Typography style={{ fontSize: 19, paddingTop: 3 }} variant="h3">
                Pictures
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 5 }}>
            <Link className={classes.link} href={"/videos"}>
              <Typography style={{ fontSize: 19, paddingTop: 3 }} variant="h3">
                Videos
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 5 }}>
            <Link className={classes.link} href={"/graphic-design"}>
              <Typography style={{ fontSize: 19, paddingTop: 3 }} variant="h3">
                Graphic Design
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.divider}></Typography>
          </Grid>
        </Grid>
        <SocialMedia />
      </Grid>
    </Grid>
  );
}
